/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react'
import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'

import { submitNewsletterForm } from 'helpers/middleware'
import { isStorageSupported } from 'helpers/utils'

import ArrowIcon from 'public/icons/arrow.svg'

import style from './NewsletterSubscription.style'

const NewsletterSubscriptionStyled = styled.div(props => ({ ...style(props) }))

const validateEmail = input => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input)

const languages = [
    { code: 'en', label: 'English' },
    { code: 'de', label: 'Deutsch' }
]

const NewsletterSubscription = ({ t, i18n, inverted, placeholder }) => {
    const [form, setForm] = useState({
        email: '',
        language: i18n.language || languages[0].code,
        firstName: '',
        lastName: ''
    })
    const [errors, setErrors] = useState({
        email: false,
        language: false,
        firstName: false,
        lastName: false
    })

    const setFormValue = (key, value) => {
        setForm(state => ({
            ...state,
            [key]: value
        }))
        setErrors(state => ({
            ...state,
            [key]: false
        }))
    }

    const initialState = () => {
        if (process.browser && isStorageSupported(window, 'sessionStorage')) {
            if (sessionStorage.getItem('subscribed')) return true
        }
        return false
    }
    const [submitted, setSubmitted] = useState(initialState)

    const handleSubmit = () => {
        if (!submitted) {
            const formErrors = {
                email: !form.email || !validateEmail(form.email),
                language: !form.language,
                firstName: !form.firstName,
                lastName: !form.lastName
            }
            setErrors(state => ({
                ...state,
                ...formErrors
            }))
            if (Object.values(formErrors).some(Boolean)) {
                return
            }
            submitNewsletterForm(form)
            setSubmitted(true)
            if (process.browser && isStorageSupported(window, 'sessionStorage')) {
                sessionStorage.setItem('subscribed', 'true')
            }
        }
    }

    return (
        <>
            <NewsletterSubscriptionStyled inverted={inverted}>
                {!submitted ? (
                    <>
                        <div className="row">
                            <input
                                type="text"
                                placeholder={t('common:name')}
                                className={errors.firstName ? 'has-error' : null}
                                value={form.firstName}
                                onChange={e => setFormValue('firstName', e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder={t('common:last-name')}
                                className={errors.lastName ? 'has-error' : null}
                                value={form.lastName}
                                onChange={e => setFormValue('lastName', e.target.value)}
                            />
                        </div>
                        <div className="row">
                            <strong>{t('common:language')}</strong>
                            {languages.map(item => (
                                <label
                                    key={item.code}
                                    className={`radio${item.code === form.language ? ' checked' : ''}`}>
                                    <input
                                        type="radio"
                                        checked={item.code === form.language}
                                        onChange={() => setFormValue('language', item.code)}
                                    />
                                    {item.label}
                                </label>
                            ))}
                        </div>
                    </>
                ) : null}
                <div className="row">
                    {!submitted && (
                        <>
                            <input
                                type="email"
                                className={errors.email ? 'has-error' : null}
                                value={form.email}
                                disabled={submitted}
                                placeholder={placeholder}
                                onChange={e => setFormValue('email', e.target.value)}
                            />

                            <button type="button" className="NewsletterSubscriptionButton" onClick={handleSubmit}>
                                {t('sign-up')}
                                <span>
                                    <ArrowIcon />
                                </span>
                            </button>
                        </>
                    )}

                    {submitted && (
                        <div className="submitted">
                            <span>{t('thank-you-subscription')}</span>
                        </div>
                    )}
                </div>
            </NewsletterSubscriptionStyled>
        </>
    )
}

NewsletterSubscription.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.oneOfType([() => null, PropTypes.object]).isRequired,
    inverted: PropTypes.bool,
    placeholder: PropTypes.string.isRequired
}

NewsletterSubscription.defaultProps = {
    inverted: false
}

export default withTranslation('controls', 'common')(withTheme(NewsletterSubscription))
