import { createFontVariations, responsive, toRem, toRems, convertHexToRGB as rgba } from 'helpers/theme'
import merge from 'deepmerge'

/* eslint-disable import/no-absolute-path, import/extensions, quote-props, max-len */
const style = theme => {
    const { fontSizes, rootFontSize } = theme.typography
    const globals = {
        '.isHidden': {
            display: 'none'
        },
        '.no-bullet': {
            padding: 0,
            margin: 0,
            listStyle: 'none'
        }
    }
    const container = {
        '.container': {
            width: '100%',
            padding: toRems([0, 30]),
            [theme.breakpoints.up(1140)]: {
                width: toRem(1080),
                margin: '0 auto',
                padding: 0
            }
        }
    }

    return merge.all([
        theme.normalize,
        theme.typography,
        createFontVariations({ fontSizes, rootFontSize }),
        theme.global,
        globals,
        container
    ])
}

export default style
