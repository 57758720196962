/** @jsx jsx */
import { useContext, useEffect, useState } from 'react'
import { jsx } from '@emotion/core'
import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import AppContext from 'contexts/AppContext'
import { Router, withTranslation } from 'server/i18n'
import smoothscroll from 'smoothscroll-polyfill'

import IconArrow from 'public/icons/arrow.svg'
import style from './Base.style'

const BaseLayoutStyled = styled.main(props => ({ ...style(props) }))

const BaseLayout = ({ t, children }) => {
    const { setActiveFilters } = useContext(AppContext)
    const [specials, setSpecials] = useState(false)
    const [listing, setListing] = useState(false)
    const [oneScreenDown, setOneScreenDown] = useState(false)

    const isBrowser = typeof window !== 'undefined'

    const getScreenBreakpoint = () => {
        return window.scrollY <= window.innerHeight
    }

    const handleScroll = () => {
        setOneScreenDown(isBrowser && getScreenBreakpoint())
    }

    const scrollToElement = () => {
        smoothscroll.polyfill()
        if (process.browser) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }

    const onRouteChange = () => {
        const filters = Object.entries(Router.query || {}).reduce((all, [key, values]) => {
            all[key] = Array.isArray(values) ? values : [values]
            return all
        }, {})

        setSpecials(Router.route === '/specials')
        setListing(Router.route === '/buy-a-yacht')

        setActiveFilters(filters)
    }

    useEffect(() => {
        Router.events.on('routeChangeComplete', onRouteChange)
        onRouteChange()
        setSpecials(Router.route === '/specials')
        setListing(Router.route === '/buy-a-yacht')
        return () => {
            Router.events.off('routeChangeComplete', onRouteChange)
        }
    }, [])

    useEffect(() => {
        const isOneScreenDown = isBrowser && getScreenBreakpoint()

        if (isBrowser) {
            window.addEventListener('scroll', handleScroll)
        }

        setOneScreenDown(isOneScreenDown)

        return () => {
            if (isBrowser) window.removeEventListener('scroll', handleScroll)
        }
    }, [oneScreenDown])

    return (
        <BaseLayoutStyled specials={specials} listing={listing} oneScreenDown={oneScreenDown}>
            <button className="backToTopButton" onClick={() => scrollToElement()} type="button">
                <IconArrow /> {t('back-to-top')}
            </button>
            {children}
        </BaseLayoutStyled>
    )
}

BaseLayout.propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default withTranslation('common')(withTheme(BaseLayout))
