import { useState } from 'react'
import PropTypes from 'prop-types'
import AppContext from 'contexts/AppContext'

const ContextWrapper = ({ children }) => {
    const [boatListCache, setBoatListCache] = useState([])
    const [activeFilters, setActiveFilters] = useState({})
    const [filterPosition, setFilterPosition] = useState(0)
    const [numberOfFilteredBoats, setNumberOfFilteredBoats] = useState(null)

    return (
        <AppContext.Provider
            value={{
                boatListCache,
                setBoatListCache,
                activeFilters,
                setActiveFilters,
                filterPosition,
                setFilterPosition,
                numberOfFilteredBoats,
                setNumberOfFilteredBoats
            }}>
            {children}
        </AppContext.Provider>
    )
}

ContextWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

ContextWrapper.defaultProps = {
    children: undefined
}

export default ContextWrapper
