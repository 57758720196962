import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Link /* , i18n */ } from 'server/i18n'

import ConditionalWrapper from 'helpers/components/ConditionalWrapper'

import style from './Button.style'

const ButtonStyled = styled.button(props => ({ ...style(props) }))

const Button = ({
    variation,
    link,
    children,
    dark,
    white,
    blue,
    small,
    smallest,
    onClick,
    type,
    disabled,
    dataAttrs,
    hasIcon,
    externalLink
}) => {
    const condition = !!link

    const getDataAttrs = () => {
        if (!dataAttrs) {
            return null
        }
        return Object.keys(dataAttrs).reduce((attrs, key) => {
            attrs[`data-${key}`] = dataAttrs[key]
            return attrs
        }, {})
    }

    return (
        // eslint-disable-next-line no-shadow
        <ConditionalWrapper
            condition={condition}
            wrap={children => (
                <Link href={link} passHref>
                    {children}
                </Link>
            )}>
            <ButtonStyled
                as={link || externalLink ? 'a' : 'button'}
                onClick={onClick}
                className={variation}
                href={link || externalLink}
                dark={dark}
                white={white}
                blue={blue}
                small={small}
                smallest={smallest}
                type={type}
                disabled={disabled}
                hasIcon={hasIcon}
                {...getDataAttrs()}>
                <span>{children}</span>
            </ButtonStyled>
        </ConditionalWrapper>
    )
}

Button.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    variation: PropTypes.string,
    onClick: PropTypes.func,
    link: PropTypes.string,
    externalLink: PropTypes.string,
    dark: PropTypes.bool,
    white: PropTypes.bool,
    blue: PropTypes.bool,
    small: PropTypes.bool,
    smallest: PropTypes.bool,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    dataAttrs: PropTypes.shape({}),
    hasIcon: PropTypes.bool
}

Button.defaultProps = {
    variation: 'primary',
    onClick: () => {},
    link: null,
    externalLink: null,
    dark: false,
    white: false,
    blue: false,
    small: false,
    smallest: false,
    type: undefined,
    disabled: false,
    dataAttrs: undefined,
    hasIcon: false
}

export default withTheme(Button)
