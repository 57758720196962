import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import IconFacebook from 'public/icons/facebook.svg'
import IconYouTube from 'public/icons/youtube.svg'
import IconInstagram from 'public/icons/instagram.svg'

import style from './SocialLink.style'

const SocialLinkStyled = styled.div(props => ({ ...style(props) }))

const SocialLink = ({ link, title, onClick, dataAttrs }) => {
    const getDataAttrs = () => {
        if (!dataAttrs) {
            return null
        }
        return Object.keys(dataAttrs).reduce((attrs, key) => {
            attrs[`data-${key}`] = dataAttrs[key]
            return attrs
        }, {})
    }

    const getSocialIcon = () => {
        let icon = title
        if (link.includes('facebook.com')) {
            icon = <IconFacebook />
        } else if (link.includes('youtube.com')) {
            icon = <IconYouTube />
        } else if (link.includes('instagram.com')) {
            icon = <IconInstagram />
        }
        return icon
    }

    const getSocialLinkType = () => {
        let type = title
        if (link.includes('facebook.com')) {
            type = 'Facebook'
        } else if (link.includes('youtube.com')) {
            type = 'YouTube'
        } else if (link.includes('instagram.com')) {
            type = 'Instagram'
        }
        return type
    }

    return (
        <SocialLinkStyled>
            <a
                href={link}
                title={title}
                target="_blank"
                rel="noreferrer noopener"
                {...getDataAttrs()}
                data-gtm-event="link"
                data-gtm-category="Social"
                data-gtm-action={getSocialLinkType()}
                data-gtm-label={title}
                onClick={onClick}>
                {getSocialIcon()}
            </a>
        </SocialLinkStyled>
    )
}

SocialLink.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    dataAttrs: PropTypes.shape({})
}

SocialLink.defaultProps = {
    onClick: () => {},
    dataAttrs: null
}

export default withTheme(SocialLink)
