/* eslint-disable quote-props */
const style = ({ theme }) => ({
    display: 'inline-flex',
    verticalAlign: 'middle',
    a: {
        display: 'block',
        transition: 'transform 300ms ease-in-out 0ms',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    svg: {
        display: 'block'
    }
})

export default style
