import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import { withTranslation } from 'server/i18n'
import { withRouter } from 'next/router'
import PropTypes from 'prop-types'
// import { setCookie } from 'nookies'

import GlobeIcon from 'public/icons/globe.svg'

import { getBoatLink } from 'helpers/middleware'
import style from './LanguageSwitcher.style'

function getLanguageHostname(lang, currentHostname) {
    // simple environment detection
    if (currentHostname.includes('localhost')) {
        return `merk${lang}.localhost:9999`
    }
    if (currentHostname.includes('preview')) {
        return `merk${lang}.preview.human.hr`
    }

    return lang === 'de' ? 'merkandmerk.de' : 'merkandmerk.com'
}

const LanguageSwitcherStyled = styled.div(props => ({ ...style(props) }))

const LanguageSwitcher = ({ router, i18n }) => {
    const handleClick = async lang => {
        try {
            const newLanguageHost = getLanguageHostname(lang, window.location.host)

            const { query } = router
            if (query.buy) {
                const { as } = getBoatLink(query.type, query.slug, lang, true)

                // @NOTE: cannot use router.replace() since it doesn't update path correctly and cannot handle host changes,
                // result being something like
                // http://merkde.localhost:9999/[buy]/[type]/[model]/[slug]?buy=kaufen&type=motoryacht&model=fashion-68-ht&slug=fashion-68-ht
                //
                // See also: https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
                // "The new URL must be of the same origin as the current URL"
                window.location.replace(`${window.location.protocol}//${newLanguageHost}${as}`)
            } else {
                window.location.replace(`${window.location.protocol}//${newLanguageHost}${router.asPath}`)
            }
        } catch (e) {
            console.error(`Change language to ${lang} failed`, e)
        }
    }

    return (
        <LanguageSwitcherStyled>
            {i18n.language === 'en' ? (
                <button
                    onClick={() => handleClick('de')}
                    type="button"
                    className={i18n.language === 'de' ? 'active' : ''}>
                    <GlobeIcon /> De
                </button>
            ) : (
                <button
                    onClick={() => handleClick('en')}
                    type="button"
                    className={i18n.language === 'en' ? 'active' : ''}>
                    <GlobeIcon /> En
                </button>
            )}
        </LanguageSwitcherStyled>
    )
}

LanguageSwitcher.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    router: PropTypes.object.isRequired,
    i18n: PropTypes.oneOfType([() => null, PropTypes.object]).isRequired
}

LanguageSwitcher.defaultProps = {}

export default withTranslation('common')(withTheme(withRouter(LanguageSwitcher)))
