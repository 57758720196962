import { toRem } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    background: theme.colors.goldDark,
    zIndex: 999,
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        flexDirection: 'column',
        padding: '20px 40px'
    },

    [theme.breakpoints.up('lg')]: {
        flex: 1
    },

    [theme.breakpoints.up(1185)]: {
        flex: 'unset'
    },

    '& .Menu': {
        listStyle: 'none',
        margin: 0,
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },

        [theme.breakpoints.up('lg')]: {
            flex: 1,
            justifyContent: 'space-around'
        },

        [theme.breakpoints.up(1185)]: {
            flex: 'unset',
            justifyContent: 'unset'
        },

        li: {
            padding: '15px 0',

            [theme.breakpoints.up(1185)]: {
                padding: '0 20px'
            },
            '&:first-of-type': {
                [theme.breakpoints.down('md')]: {
                    paddingTop: 0
                }
            },
            '&:last-of-type': {
                [theme.breakpoints.down('md')]: {
                    paddingBottom: 0
                }
            },
            '&.inquiry': {
                width: '100%',
                paddingTop: '30px',
                paddingBottom: '40px',
                a: {
                    width: '100%',
                    minHeight: '55px'
                },

                [theme.breakpoints.up('lg')]: {
                    display: 'none'
                }
            },
            '&.primary': {
                display: 'inline-block',

                a: {
                    color: theme.colors.blueDark,
                    position: 'relative',
                    display: 'inline-block',
                    fontSize: toRem(20),

                    '&:after': {
                        content: '""',
                        display: 'block',
                        width: 0,
                        height: 2,
                        bottom: -3,
                        position: 'absolute',
                        background: theme.colors.blueDark,
                        transition: 'width 250ms ease-in'
                    },

                    '&:hover': {
                        '&:after': {
                            width: '100%'
                        }
                    },

                    [theme.breakpoints.up('lg')]: {
                        fontSize: toRem(16)
                    },
                    [theme.breakpoints.up('xl')]: {
                        fontSize: toRem(18)
                    }
                },

                svg: {
                    display: 'block',
                    position: 'absolute',
                    bottom: 14,
                    right: -13,
                    width: 13,
                    height: 12
                }
            }
        }
    },

    '& .WhatsNewButton': {
        '&--desktop, &--mobile': {
            alignItems: 'center',
            fontSize: toRem(14),
            fontWeight: 600,
            marginBottom: toRem(60),
            color: theme.colors.blueMid,

            svg: {
                marginRight: 10,
                animationName: 'scale',
                animationDuration: '3s',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'ease-in-out',

                path: {
                    animationName: 'blink',
                    animationDuration: '3s',
                    animationIterationCount: 'infinite',
                    animationTimingFunction: 'ease-in-out'
                }
            }
        },

        '&--mobile': {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                display: 'none'
            }
        },

        '&--desktop': {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'flex',
                marginBottom: 0,
                fontSize: toRem(16),
                marginLeft: 65
            }
        },

        '@keyframes scale': {
            '0%': { transform: 'scale(1,1)' },
            '50%': { transform: 'scale(1.3, 1.3)' },
            '100%': { transform: 'scale(1,1)' }
        },

        '@keyframes blink': {
            '0%': { fill: theme.colors.blueMid },
            '50%': { fill: theme.colors.white },
            '100%': { fill: theme.colors.blueMid }
        }
    },
    '& .NewsletterSubscription': {
        display: 'none',
        maxWidth: toRem(480),

        [theme.breakpoints.up('lg')]: {
            display: 'block'
        }
    }
})

export default style
