import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation, Link } from 'server/i18n'

import StarIcon from 'public/icons/star.svg'
import Button from 'components/Button'

import style from './NavigationPanel.style'

const NavigationPanelStyled = styled.nav(props => ({ ...style(props) }))

const NavigationPanel = ({ t }) => {
    return (
        <NavigationPanelStyled>
            <ul className="Menu">
                <li className="primary">
                    <Link href="/buy-a-yacht">
                        <a>{t('buy-a-yacht')}</a>
                    </Link>
                </li>
                <li className="primary">
                    <Link href="/manage">
                        <a>{t('manage-a-yacht')}</a>
                    </Link>
                </li>
                <li className="primary">
                    <Link href="/sell">
                        <a>{t('sell-a-yacht')}</a>
                    </Link>
                </li>
                <li className="primary">
                    <Link href="/yacht-news">
                        <a>
                            Yacht News <StarIcon />
                        </a>
                    </Link>
                </li>
                <li className="primary">
                    <Link href="/about">
                        <a>{t('about-us')}</a>
                    </Link>
                </li>
                <li className="primary">
                    <Link href="/newsletter">
                        <a>{t('newsletter')}</a>
                    </Link>
                </li>
                <li className="primary">
                    <Link href="/contact">
                        <a>{t('contact')}</a>
                    </Link>
                </li>
                <li className="inquiry">
                    <Button
                        blue
                        link="/inquiry"
                        hasIcon
                        variation="quaternary rounded inquiry-navigation"
                        dataAttrs={{
                            'gtm-event': 'link',
                            'gtm-label': 'Send inquiry',
                            'gtm-action': 'Send inquiry',
                            'gtm-category': 'Navigation'
                        }}>
                        {t('send-inquiry')}
                    </Button>
                </li>
            </ul>
        </NavigationPanelStyled>
    )
}

NavigationPanel.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('common')(withTheme(NavigationPanel))
