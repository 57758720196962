import { toRem } from 'helpers/theme'

const getColor = (theme, dark, white, blue) => {
    if (dark) {
        return theme.colors.blueMidAlt
    }
    if (white) {
        return theme.colors.white
    }
    if (blue) {
        return theme.colors.white
    }

    return theme.colors.goldDark
}

const getHoverBackground = (theme, dark, white, blue) => {
    if (dark) {
        return theme.colors.blueMid
    }
    if (white) {
        return theme.colors.white
    }
    if (blue) {
        return theme.colors.blueDark
    }

    return theme.colors.goldDark
}

const getHoverColor = (theme, dark, white, blue) => {
    if (dark) {
        return theme.colors.goldDark
    }
    if (white) {
        return theme.colors.blueMid
    }

    return theme.colors.blueMid
}

const getFontSize = (small, smallest) => {
    if (small || smallest) {
        return 12
    }

    return 14
}

const getPadding = (small, smallest) => {
    if (small) {
        return '10px 20px'
    }

    if (smallest) {
        return '5px 20px'
    }

    return '15px 20px'
}

/* eslint-disable quote-props */
const style = ({ theme, dark, white, blue, small, smallest, hasIcon }) => ({
    display: 'inline-flex',
    color: `${getColor(theme, dark, white, blue)}`,
    fontSize: toRem(getFontSize(small, smallest)),
    padding: `${getPadding(small, smallest)}`,
    borderRadius: 0,
    transition: 'all 0.5s ease',
    alignItems: 'center',
    minWidth: 120,

    'svg path': {
        transition: 'all 0.5s ease',
        fill: getColor(theme, dark, white, blue)
    },

    [theme.breakpoints.up('md')]: {
        fontSize: toRem(16),
        padding: '15px 20px'
    },

    ...(hasIcon && {
        '& > span': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& svg': {
                display: 'block',
                flexShrink: 0,
                marginLeft: toRem(5)
            }
        }
    }),

    '&.primary': {
        border: `2px solid ${getColor(theme, dark, white, blue)}`,
        minWidth: 110,
        justifyContent: 'center',

        '&:hover': {
            background: getHoverBackground(theme, dark, white, blue),
            color: getHoverColor(theme, dark, white, blue),

            'svg path': {
                fill: getHoverColor(theme, dark, white, blue)
            }
        },

        [theme.breakpoints.up('md')]: {
            minWidth: 120
        },

        [theme.breakpoints.up('lg')]: {
            minWidth: 200
        }
    },

    '&.rounded': {
        borderRadius: '100px'
    },

    '&.secondary': {
        justifyContent: 'center',
        background: theme.colors.goldDark,
        fontWeight: theme.typography.fontWeight.medium,
        width: 'auto',
        color: theme.colors.blueLight,

        '&:hover': {
            background: theme.colors.goldDarker
        },

        '& svg path': {
            fill: theme.colors.blueMid
        }
    },

    '&.tertiary': {
        borderRadius: '100px',
        justifyContent: 'center',
        padding: '10px 18px',
        background: theme.colors.goldDark,
        fontSize: toRem(12),
        fontWeight: theme.typography.fontWeight.medium,
        width: 'auto',
        color: theme.colors.blueMid,

        '&:hover': {
            background: theme.colors.goldDarker
        },

        '& svg path': {
            fill: theme.colors.blueMid
        },

        [theme.breakpoints.up('md')]: {
            padding: '8px 20px',
            fontSize: toRem(16)
        },

        '&.bigOnLarge': {
            [theme.breakpoints.up('xxl')]: {
                padding: '15px 35px'
            }
        }
    },

    '&.quaternary': {
        border: `2px solid ${theme.colors.blueMidAlt}`,
        justifyContent: 'center',
        background: theme.colors.blueMidAlt,
        maxHeight: '44px',
        fontSize: toRem(14),
        padding: '10px 14px',

        '&:hover': {
            background: getHoverBackground(theme, dark, white, blue),
            border: `2px solid ${theme.colors.blueDark}`,

            'svg path': {
                fill: getHoverColor(theme, dark, white, blue)
            }
        },

        [theme.breakpoints.up('xl')]: {
            padding: '10px 20px'
        }
    },

    '&.fullWidth': {
        width: '100%'
    }
})

export default style
