import { withTheme } from 'emotion-theming'
import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation, Link } from 'server/i18n'
import { parseCookies, setCookie, destroyCookie } from 'nookies'

import Button from 'components/Button'
import CookieIcon from 'public/icons/cookie.svg'

import style from './CookieNotice.style'

const CookieNoticeStyled = styled.div(props => ({ ...style(props) }))

const CookieNotice = ({ t }) => {
    const [cookieNoticeAvailable, setCookieNoticeAvailable] = useState(false)
    const [cookieNoticeOpen, setCookieNoticeOpen] = useState(false)

    const acceptCookieNotice = () => {
        setCookieNoticeOpen(false)
        setCookie(null, 'cwCookieInfo', '1', {
            maxAge: 365 * 24 * 60 * 60,
            path: '/'
        })
        if (process.browser)
            window.dataLayer.push({
                event: 'GAEvent',
                eventCategory: 'Cookie',
                eventAction: 'Cookie notice',
                eventLabel: 'Cookie accept'
            })
    }

    const declineCookieNotice = () => {
        setCookieNoticeOpen(false)

        if (process.browser)
            window.dataLayer.push({
                event: 'GAEvent',
                eventCategory: 'Cookie',
                eventAction: 'Cookie notice',
                eventLabel: 'Cookie decline'
            })
    }

    const showCookieNotice = () => {
        const merkCookie = parseCookies({}).cwCookieInfo
        if (!merkCookie) {
            setCookieNoticeAvailable(true)
            setCookieNoticeOpen(true)
        }
    }

    useEffect(() => {
        showCookieNotice()
    }, [])

    return (
        <>
            {cookieNoticeAvailable && (
                <CookieNoticeStyled open={cookieNoticeOpen} aria-hidden={!cookieNoticeOpen}>
                    <div className="cookieNotice">
                        <CookieIcon className="cookieNotice-icon" />
                        <div className="cookieNotice-content">
                            <div className="cookieNotice-copy">
                                <div className="cookieNotice-copyDesktop">
                                    {t('cookie-notice-copy')}{' '}
                                    <Link href="/privacy-policy">
                                        <a>{t('privacy-policy')}</a>
                                    </Link>
                                    .
                                </div>
                                <div className="cookieNotice-copyMobile">{t('cookie-notice-copy-short')}</div>
                            </div>
                            <div className="cookieNotice-action">
                                <Button variation="primary rounded" dark small onClick={acceptCookieNotice}>
                                    {t('cta-accept')}
                                </Button>
                                <Button variation="primary rounded" dark small onClick={declineCookieNotice}>
                                    {t('cta-no-thanks')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </CookieNoticeStyled>
            )}
        </>
    )
}

CookieNotice.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('common')(withTheme(CookieNotice))
