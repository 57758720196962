/* eslint-disable import/prefer-default-export */
const isStorageSupported = (globalObject, storageType) => {
    try {
        const storage = globalObject[storageType]
        storage.setItem('test', 'test')
        storage.removeItem('test')
        return true
    } catch (err) {
        return false
    }
}

export default isStorageSupported
