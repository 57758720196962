import { toRem } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, menuOpen }) => ({
    background: theme.colors.goldDark,
    padding: '20px 40px',
    display: 'flex',
    position: 'relative',
    zIndex: 999,
    width: '100%',
    top: 0,
    left: 0,
    height: '80px',

    [theme.breakpoints.up('lg')]: {
        height: '100px',
        position: 'fixed',
        justifyContent: 'space-between',
        padding: '20px 20px'
    },

    [theme.breakpoints.up('xxl')]: {
        justifyContent: 'space-around'
    },

    '& .LangSwitcher': {
        display: 'flex',

        [theme.breakpoints.down('md')]: {
            marginLeft: 'auto',
            display: 'flex',
            marginRight: toRem(10),

            '> a': {
                display: 'none'
            }
        },

        [theme.breakpoints.up('lg')]: {
            alignItems: 'center',

            '.Languages': {
                marginLeft: 52
            }
        },

        [theme.breakpoints.up('xl')]: {
            '.Languages': {
                marginLeft: 62
            }
        }
    },

    '& .LogoContainer': {
        width: 80,

        svg: {
            width: '100%',
            height: '100%',
            path: {
                transition: 'fill 0.5s ease'
            }
        },

        '&:hover svg path': {
            fill: theme.colors.blueMid
        },

        [theme.breakpoints.up('lg')]: {
            width: 120
        }
    },

    '& .MenuTrigger': {
        width: 40,
        height: 40,
        position: 'relative',

        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },

        span: {
            position: 'absolute',
            display: 'block',
            width: 20,
            height: 2,
            background: theme.colors.blueDark,
            transition: menuOpen
                ? 'top 0.2s ease, transform 0.2s 0.2s ease, background 0.5s ease, opacity 0.2s ease'
                : 'transform 0.2s ease, top 0.2s 0.2s ease',
            transform: menuOpen ? 'rotate(-45deg)' : null,
            transformOrigin: 'center center',
            top: menuOpen ? '50%' : '50%',
            left: '50%',
            marginLeft: -10,
            cursor: 'pointer',
            borderRadius: 4,
            opacity: 1,

            [theme.breakpoints.up('lg')]: {
                width: 40,
                marginLeft: -20,
                left: 'auto',
                right: 20
            },

            [theme.breakpoints.up('xl')]: {
                right: 40
            },

            '&:first-of-type': {
                top: menuOpen ? '50%' : 'calc(50% - 7px)',
                transform: menuOpen ? 'rotate(45deg)' : null
            },

            '&:last-of-type': {
                top: menuOpen ? '50%' : 'calc(50% + 7px)',
                transform: menuOpen ? 'rotate(45deg)' : null,
                opacity: menuOpen ? 0 : 1
            }
        }
    }
})

export default style
