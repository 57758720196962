import { useState, useEffect } from 'react'
import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import Router from 'next/router'
import styled from '@emotion/styled'
import { Link, withTranslation } from 'server/i18n'

import Button from 'components/Button'
import Logo from 'components/Logo'
import LanguageSwitcher from 'components/LanguageSwitcher'
import NavigationPanel from 'components/NavigationPanel'

import style from './Navigation.style'

const NavigationStyled = styled.nav(props => ({ ...style(props) }))

const Navigation = ({ t, boatUrl }) => {
    const [navPanel, setNavPanel] = useState(false)
    const isBrowser = typeof window !== 'undefined'

    const toggleMenu = () => {
        setNavPanel(!navPanel)

        if (isBrowser)
            window.dataLayer.push({
                event: 'GAEvent',
                eventCategory: 'Main navigation',
                eventAction: 'Toggle navigation',
                eventLabel: 'Main navigation'
            })
    }

    const handleNavigationOpen = () => {
        if (window.innerWidth > 1023) {
            setNavPanel(true)
        } else {
            setNavPanel(false)
        }
    }

    useEffect(() => {
        if (isBrowser) {
            window.addEventListener('resize', handleNavigationOpen)
        }

        handleNavigationOpen()

        Router.events.on('routeChangeComplete', handleNavigationOpen)

        return () => {
            if (isBrowser) window.removeEventListener('scroll', handleNavigationOpen)
            Router.events.off('routeChangeComplete', handleNavigationOpen)
        }
    }, [])

    return (
        <NavigationStyled menuOpen={navPanel}>
            <div className="LogoContainer">
                <Link href="/">
                    <a>
                        <Logo />
                    </a>
                </Link>
            </div>

            {navPanel ? <NavigationPanel /> : null}

            <div className="LangSwitcher">
                {!boatUrl && (
                    <Button
                        blue
                        link="/inquiry"
                        hasIcon
                        variation="quaternary rounded inquiry-navigation"
                        dataAttrs={{
                            'gtm-event': 'link',
                            'gtm-label': 'Send inquiry',
                            'gtm-action': 'Send inquiry',
                            'gtm-category': 'Navigation'
                        }}>
                        {t('send-inquiry')}
                    </Button>
                )}
                {navPanel ? <LanguageSwitcher /> : null}
            </div>
            <button className="MenuTrigger" type="button" onClick={toggleMenu}>
                <span />
                <span />
                <span />
            </button>
        </NavigationStyled>
    )
}

Navigation.propTypes = {
    t: PropTypes.func.isRequired,
    boatUrl: PropTypes.string
}

Navigation.defaultProps = {
    boatUrl: ''
}
export default withTranslation(['controls'])(withTheme(Navigation))
