import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, inverted }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',

    '.row': {
        color: inverted ? '#E3B05F' : '#041D2E',
        maxWidth: 400,
        display: 'flex',
        width: '100%',
        marginBottom: toRem(20),
        strong: {
            fontWeight: 'normal',
            fontSize: toRem(14),
            color: inverted ? '' : theme.colors.blueMidAlt
        },

        '&:last-of-type': {
            marginBottom: 0
        }
    },

    input: {
        flex: 1,
        minWidth: 0,
        fontSize: toRem(12),
        fontWeight: theme.typography.fontWeight.medium,
        border: '1px solid #D4A459',
        borderRadius: 5,
        background: '#D4A459',
        height: 60,
        padding: '0 20px',
        marginLeft: 20,
        outline: 'none',
        color: theme.colors.blueMidAlt,

        '&[type="email"]': {
            borderRadius: '5px 0 0 5px'
        },

        '&.has-error': {
            borderColor: theme.colors.red
        },

        '&:first-of-type': {
            marginLeft: 0
        },

        [theme.breakpoints.up('lg')]: {
            padding: '30px',
            height: '80px !important'
        },

        '&::placeholder': {
            fontSize: toRem(12),
            fontWeight: 500,
            color: theme.colors.blueMidAlt
        }
    },

    '& .submitted': {
        borderRadius: '5px',
        background: '#041D2E url(icons/checkmark.svg) no-repeat right 20px center',
        color: theme.colors.white,
        height: 80,
        padding: toRems([0, 60, 0, 20]),
        display: 'flex',
        alignItems: 'center'
    },

    '.radio': {
        marginLeft: 20,
        paddingLeft: 30,
        position: 'relative',
        cursor: 'pointer',
        fontSize: toRem(14),
        color: inverted ? '' : theme.colors.blueMidAlt,
        input: {
            display: 'none'
        },
        '&:before': {
            content: '""',
            display: 'block',
            width: 18,
            height: 18,
            borderRadius: '50%',
            border: `2px solid ${inverted ? '#E3B05F' : '#0E425B'}`,
            position: 'absolute',
            top: '50%',
            left: 10,
            transform: 'translate(-50%, -50%)'
        },
        '&:after': {
            content: '""',
            display: 'none',
            width: 7,
            height: 7,
            borderRadius: '50%',
            backgroundColor: inverted ? '#E3B05F' : '#0E425B',
            position: 'absolute',
            top: '50%',
            left: 10,
            transform: 'translate(-50%, -50%)'
        },
        '&.checked': {
            '&:after': {
                display: 'block'
            }
        }
    },

    '& .NewsletterSubscriptionButton': {
        display: 'inline-flex',
        alignItems: 'center',
        background: inverted ? theme.colors.blueMid : theme.colors.blueMidAlt,
        color: theme.colors.white,
        borderRadius: '0 5px 5px 0',
        fontSize: toRem(12),
        fontWeight: theme.typography.fontWeight.medium,
        letterSpacing: 1,
        padding: '0 13px',
        height: 60,
        flexShrink: 0,

        [theme.breakpoints.up('lg')]: {
            padding: '0 30px',
            height: 80
        },

        span: {
            width: 10,
            transform: 'rotate(-90deg)',
            marginTop: -2,
            marginLeft: 5,

            'svg path': {
                stroke: theme.colors.white,
                strokeWidth: 3
            }
        }
    }
})

export default style
