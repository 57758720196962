import {
    breakpoints,
    colors as globalColors,
    normalize,
    typography as globalTypography,
    zIndex as globalZIndex,
    global
} from 'theme/globals'
import { createMediaQueries } from 'helpers/theme'

import colors from './colors'

export default {
    breakpoints: {
        ...createMediaQueries(breakpoints)
    },
    colors: {
        ...globalColors,
        ...colors
    },
    typography: {
        ...globalTypography
    },
    zIndex: {
        ...globalZIndex
    },
    normalize,
    global
}
