export default {
    title: 'Merk & Merk',
    description: 'Welcome to Merk & Merk',
    openGraph: {
        type: 'website',
        locale: 'en_IE',
        site_name: 'Merk & Merk'
    },
    twitter: {
        handle: '@handle',
        site: '@site',
        cardType: 'summary_large_image'
    },
    facebook: {
        appId: 'AppID'
    }
}
