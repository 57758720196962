const style = ({ theme, menuOpen, aboveFold, specials, listing, oneScreenDown }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1,
    background: theme.colors.blueDark,
    color: theme.colors.white,
    transition: 'all 0.5s ease',

    [theme.breakpoints.up('lg')]: {
        paddingTop: '100px'
    },

    '& .backToTopButton': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        width: 125,
        height: 48,
        bottom: oneScreenDown ? -80 : 0,
        right: 0,
        padding: '0 10px',
        background: theme.colors.goldDark,
        color: theme.colors.blueMidAlt,
        fontSize: 14,
        fontWeight: 600,
        zIndex: 99,
        borderRadius: '4px 0 0 0',
        transition: 'bottom 0.5s ease',

        svg: {
            width: 16,
            transform: 'rotate(180deg)',
            marginTop: 5,

            path: {
                stroke: theme.colors.blueMidAlt
            }
        },

        [theme.breakpoints.up('lg')]: {
            width: 171,
            height: 78,
            right: 60,
            borderRadius: '4px 4px 0 0',
            padding: '0 30px'
        }
    }
})

export default style
