import { toRem } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    display: 'flex',
    button: {
        color: theme.colors.blueDark,
        marginLeft: toRem(10),
        display: 'inline-flex',
        alignItems: 'center',

        svg: {
            width: '1rem',
            height: '1rem',
            marginRight: toRem(6)
        },

        [theme.breakpoints.up('xl')]: {
            fontSize: toRem(18),
            marginLeft: toRem(20)
        }
    }
})

export default style
