import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, open }) => ({
    display: 'block',
    width: '100%',
    padding: toRems([0, 10]),
    position: 'fixed',
    left: 0,
    bottom: toRem(10),
    zIndex: theme.zIndex.cookieNotice,
    transition: 'transform 0.4s ease-in-out 0s',
    transform: 'translate(0, 200%)',
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
        bottom: toRem(40)
    },
    ...(open && {
        transform: 'translate(0, 0)'
    }),
    '& .cookieNotice': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: toRem(340),
        margin: '0 auto',
        padding: toRems([30, 20]),
        color: theme.colors.text.blueMid,
        letterSpacing: '-0.02em',
        fontSize: toRem(14),
        lineHeight: '170%',
        background: theme.colors.background.goldDark,
        borderRadius: toRem(4),
        pointerEvents: 'auto',
        boxShadow: `0 0 ${toRem(30)} rgba(0, 0, 0, 0.15)`,
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            maxWidth: toRem(800),
            padding: toRems([26, 30])
        },
        '&-icon': {
            flexShrink: 0,
            display: 'block',
            margin: toRems([-9, 10, 0, 0]),
            [theme.breakpoints.up('md')]: {
                margin: toRems([0, 30, 0, 0])
            }
        },
        '&-content': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            }
        },
        '&-copy': {
            flex: 1,
            padding: 0,
            textAlign: 'left',
            a: {
                color: theme.colors.text.blueMid,
                textDecoration: 'none',
                '&:hover': {
                    color: theme.colors.text.blueMid,
                    textDecoration: 'underline'
                }
            },
            '&Desktop': {
                display: 'none',
                [theme.breakpoints.up('md')]: {
                    display: 'block'
                }
            },
            '&Mobile': {
                display: 'block',
                [theme.breakpoints.up('md')]: {
                    display: 'none'
                }
            }
        },
        '&-action': {
            margin: toRems([18, 0, 0, 0]),
            [theme.breakpoints.up('md')]: {
                margin: toRems([0, 0, 0, 40])
            },
            '& button': {
                padding: toRems([9, 15]),
                marginRight: 5,
                minWidth: toRem(100),
                [theme.breakpoints.up('md')]: {
                    padding: toRems([5, 15])
                }
            }
        }
    }
})

export default style
