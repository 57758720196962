import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'

const ConditionalWrapper = ({ condition, wrap, children }) =>
    condition ? cloneElement(wrap(children)) : <>{children}</>

ConditionalWrapper.propTypes = {
    condition: PropTypes.bool.isRequired,
    wrap: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default withTranslation('common')(ConditionalWrapper)
