class StorageService {
    getObject = key => {
        let data = sessionStorage.getItem(key)

        try {
            data = JSON.parse(data)
        } catch (error) {
            console.error(error)
            return false
        }

        return data
    }

    setObject = (key, value) => {
        try {
            sessionStorage.setItem(key, JSON.stringify(value))

            return true
        } catch (error) {
            console.error(error)
            return false
        }
    }
}

export default new StorageService()
