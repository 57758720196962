import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    background: theme.colors.goldDark,
    padding: toRems([40, 0]),

    [theme.breakpoints.up('md')]: {
        paddingBottom: toRem(80)
    },

    [theme.breakpoints.up('lg')]: {
        paddingTop: toRem(160),
        paddingBottom: toRem(160)
    },

    '& .footerTitle': {
        fontSize: toRem(20),
        fontWeight: 600,
        color: theme.colors.blueMidAlt,
        marginBottom: toRem(20),

        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },

    '& .InfoBox-Newsletter': {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },

        [theme.breakpoints.up('lg')]: {
            flexWrap: 'nowrap',
            justifyContent: 'flex-start'
        }
    },

    '& .block-link': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        textIndent: -9999
    },

    '& .infoBox': {
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.blueMidAlt,
        background: '#D4A459',
        height: 60,
        marginBottom: 20,
        padding: '0 20px',
        borderRadius: 4,
        transition: 'all 0.5s ease',
        position: 'relative',
        fontSize: toRem(12),

        a: {
            color: theme.colors.blueMidAlt
        },

        [theme.breakpoints.up('md')]: {
            width: '45%'
        },

        [theme.breakpoints.up('lg')]: {
            width: 'auto',
            height: 80,

            '&:first-of-type': {
                marginRight: toRem(20)
            },

            '&:hover': {
                background: theme.colors.blueMid,
                color: theme.colors.goldDark,
                '.icon svg path': {
                    fill: theme.colors.goldDark
                }
            }
        },

        [theme.breakpoints.up('xl')]: {
            width: 360,
            '&:first-of-type': {
                width: 280
            },
            padding: '0 30px'
        },

        '.icon': {
            lineHeight: 0,

            'svg path': {
                transition: 'fill 0.5s ease',
                fill: theme.colors.blueMidAlt
            }
        },

        '.content': {
            fontSize: toRem(12),
            marginLeft: 20,

            [theme.breakpoints.up('lg')]: {
                fontSize: toRem(14)
            },

            [theme.breakpoints.up('xl')]: {
                fontWeight: theme.typography.fontWeight.medium
            }
        }
    },

    '& .Newsletter': {
        marginTop: toRem(40),
        marginBottom: toRem(40),

        '.footerTitle': {
            [theme.breakpoints.up('md')]: {
                display: 'block'
            },

            [theme.breakpoints.up('lg')]: {
                marginBottom: 0,
                fontSize: toRem(23)
            }
        },

        p: {
            color: theme.colors.blueMidAlt,
            marginBottom: toRem(20),
            fontSize: toRem(14)
        },

        [theme.breakpoints.up('md')]: {
            flexGrow: 1
        },

        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            marginLeft: 'auto',
            marginTop: 0,
            maxWidth: 480
        },

        [theme.breakpoints.up('xl')]: {
            width: 480
        },

        '& .row': {
            [theme.breakpoints.up('lg')]: {
                maxWidth: '100%'
            }
        },

        input: {
            [theme.breakpoints.up('lg')]: {
                height: '60px !important'
            }
        },

        button: {
            textTransform: 'none',
            padding: '0 10px',

            [theme.breakpoints.up('lg')]: {
                padding: '0 35px',
                height: 62
            }
        },

        '& .submitted': {
            background: '#D4A459 url(icons/checkmark-dark.svg) no-repeat right 20px center',
            color: '#545454'
        }
    },

    '& .Menu-LogoSocial': {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-between'
        },

        [theme.breakpoints.up('xl')]: {
            display: 'flex',
            justifyContent: 'flex-start'
        }
    },

    '& .Menu': {
        display: 'flex',
        alignItems: 'flex-start',

        [theme.breakpoints.up('md')]: {
            marginRight: toRem(80)
        },

        [theme.breakpoints.up('lg')]: {
            maxWidth: 480,
            marginRight: toRem(200),
            marginTop: -100
        },

        [theme.breakpoints.up('xl')]: {
            marginRight: 0
        },

        '.col-1, .col-2, .col-3': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',

            a: {
                color: theme.colors.blueMidAlt,
                fontSize: toRem(14),
                marginBottom: toRem(5),
                position: 'relative',
                display: 'inline-block',

                '&:last-of-type': {
                    marginBottom: 0
                }
            },

            svg: {
                display: 'none',

                [theme.breakpoints.up('lg')]: {
                    display: 'block',
                    position: 'absolute',
                    transform: 'rotate(-90deg)',
                    lineHeight: 0,
                    bottom: 7,
                    opacity: 0,
                    transition: 'all 0.5s ease'
                }
            },

            'a:hover svg': {
                opacity: 1
            }
        },

        '.col-1': {
            marginRight: toRem(50),

            [theme.breakpoints.up('xl')]: {
                marginRight: toRem(100)
            },

            a: {
                [theme.breakpoints.up('lg')]: {
                    fontSize: toRem(24),
                    fontWeight: theme.typography.fontWeight.medium
                }
            },

            svg: {
                right: -10,
                width: 20
            },

            'a:hover svg': {
                right: -20
            }
        },

        '.col-2, .col-3': {
            justifyContent: 'flex-end',

            [theme.breakpoints.up('lg')]: {
                marginLeft: 'auto',
            },

            a: {
                [theme.breakpoints.up('lg')]: {
                    marginBottom: toRem(17),
                    fontSize: toRem(16),

                    '&:last-of-type': {
                        marginBottom: 0
                    }
                }
            },

            svg: {
                right: 0,
                width: 10
            },

            'a:hover svg': {
                right: -10
            }
        },

        '.col-3': {
            [theme.breakpoints.up('lg')]: {
                marginLeft: toRem(50),
            }
        }
    },

    '& .Logo-Social': {
        display: 'flex',
        justifyContent: 'right',
        gap: 25,
        alignItems: 'center',
        marginTop: toRem(40),

        [theme.breakpoints.up('md')]: {
            flexGrow: 1
        }
    },

    '& .Logo': {
        width: 80,

        'svg path': {
            transition: 'fill 0.5s ease'
        },

        '&:hover svg path': {
            fill: theme.colors.blueMid
        },

        [theme.breakpoints.up('md')]: {
            order: 2,
            marginTop: 'auto'
        },

        [theme.breakpoints.up('lg')]: {
            width: 120
        }
    },

    '& .Social': {
        [theme.breakpoints.up('md')]: {
            order: 1,
            marginTop: 'auto'
        },

        [theme.breakpoints.up('xl')]: {
            //marginTop: -120,
            marginLeft: toRem(60)
        },

        '.title': {
            display: 'none',

            [theme.breakpoints.up('md')]: {
                display: 'block',
                color: theme.colors.blueMidAlt,
                fontSize: toRem(14),
                marginBottom: 6
            },

            [theme.breakpoints.up('lg')]: {
                marginBottom: 23,
                fontSize: toRem(16)
            }
        },

        '.icons': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            minWidth: 130,

            svg: {
                marginRight: 15,
                path: {
                    fill: theme.colors.blueMidAlt
                }
            }
        }
    }
})

export default style
